/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import LazyLoad from "react-lazy-load";
import { BRAND_COLOURS, COLORS } from "../../../constants";
import BaseLink from "../../BaseLink";
import Col from "../../theme/3.0/Grid/Col";
import { URISerializeObject } from "../../../utils/CommonUtils";
import theme from "../../theme/3.0/theme";

const LinkBox = ({ link, title, children, marginBottom }) => {
    if (typeof link !== "object") {
        return (
            <a
                href={link}
                className={"article-box"}
                title={title}
                style={
                    typeof marginBottom !== "undefined"
                        ? { marginBottom }
                        : null
                }
                target={"_blank"}
            >
                <span className={"sr-only"}>{title}</span>
                <span aria-hidden={"true"}>{children}</span>
            </a>
        );
    }

    return (
        <BaseLink routeKey={link.routeKey} params={{ ...link.query }}>
            {({ url }) => (
                <a
                    href={url ? url : "#"}
                    className={"article-box"}
                    title={title}
                    style={
                        typeof marginBottom !== "undefined"
                            ? { marginBottom }
                            : null
                    }
                >
                    <span className={"sr-only"}>{title}</span>
                    <span aria-hidden={"true"}>{children}</span>
                </a>
            )}
        </BaseLink>
    );
};

const DivBox = ({ marginBottom, children }) => (
    <div style={typeof marginBottom !== "undefined" ? { marginBottom } : null}>
        {children}
    </div>
);

DivBox.propTypes = {
    marginBottom: PropTypes.string,
    children: PropTypes.any
};

type Props = {
    image;
    hideDefaultImage?;
    imageTitle;
    imagePosition;
    imageClassName;
    wrapperContent?;
    topContent;
    middleContent;
    bottomContent;
    link;
    linkTitle;
    className;
    marginBottom;
    columnSizes?;
    wrapperClass?;
};

const ArticleBox = ({
    image,
    hideDefaultImage,
    imageTitle,
    imagePosition,
    imageClassName,
    wrapperContent,
    topContent,
    middleContent,
    bottomContent,
    link,
    linkTitle,
    className,
    marginBottom,
    columnSizes,
    wrapperClass
}: Props) => {
    className =
        typeof className === "undefined"
            ? "article-box-container"
            : "article-box-container " + className;
    imagePosition =
        typeof imagePosition !== "undefined" ? imagePosition : "bottom";

    if (typeof imageClassName === "undefined") {
        if (imagePosition === "bottom") {
            imageClassName = "article-box-image-bottom";
        }

        if (imagePosition === "cover") {
            imageClassName = "article-box-image-cover";
        }

        if (imagePosition === "bottom-cover") {
            imageClassName = "article-box-image-bottom-cover";
        }

        if (imagePosition === "square") {
            imageClassName = "article-box-image-square";
        }

        if (imagePosition === "logo") {
            imageClassName = "article-box-image-logo";
        }

        if (imagePosition === "none") {
            imageClassName = "article-box-image-hidden";
        }
    }
    const defaultImage = "/static/logo-no-alpha.jpeg";
    const BoxContainer = ({ children }) =>
        link ? (
            <LinkBox link={link} title={linkTitle} marginBottom={marginBottom}>
                {children}
            </LinkBox>
        ) : (
            <DivBox>{children}</DivBox>
        );

    BoxContainer.propTypes = {
        children: PropTypes.any
    };

    return (
        <React.Fragment>
            <style jsx global>
                {`
                    .article-box {
                        display: block;
                        position: relative;
                        z-index: 1;
                        margin-bottom: 30px;
                        text-decoration: none;
                    }

                    .article-box-container.cardboard {
                        display: flex;
                        // width: calc(100% - 30px);
                        // max-width: calc(100% - 30px);
                    }

                    .article-box-container.cardboard > a.article-box {
                        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
                        flex: 1 1 100%;
                        // background-color: rgba(0, 0, 0, 0.6);
                        background-color: white;
                        // color: white;
                        border-radius: 2px;
                    }

                    .article-box-container.carousel {
                        padding: 1rem;
                        background: #ebebeb;
                    }

                    .cardboard .article-box-content {
                        padding: 1rem;
                        border-radius: 4px;
                        transition: color 0.23s ease-in-out,
                            background-color 0.23s ease-in-out,
                            border-color 0.23s ease-in-out,
                            box-shadow 0.23s ease-in-out;
                    }

                    .cardboard.course .article-box-content {
                        display: flex;
                        height: 100%;
                    }

                    .article-box-container:last-of-kind .article-box {
                        margin-bottom: 0;
                    }

                    .article-box-image-wrapper {
                        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
                        overflow: hidden;
                        position: relative;
                        border-radius: 2px;
                    }

                    .cardboard .article-box-image-wrapper {
                        box-shadow: none;
                        padding: 1rem;
                        // background: white;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    .cardboard .article-box-image-wrapper.hide {
                        display: none;
                    }

                    .carousel .article-box-image-wrapper {
                        box-shadow: none;
                    }

                    .article-box-image-hidden {
                        visibility: hidden;
                        display: none;
                    }

                    .article-box-image-square {
                        width: 600px;
                        height: 600px;
                        max-width: 100%;
                        max-height: 200px;
                    }

                    .article-box-image-logo {
                        width: auto;
                        max-height: 100px;
                        max-width: 100%;
                        padding: 1rem;
                        z-index: 1;
                        position: relative;
                        background-color: white;
                    }

                    .article-box-image-cover {
                        position: absolute;
                        max-width: 100%;
                        max-height: 100%;
                        bottom: 0%;
                        top: 0%;
                        right: 0%;
                        left: 0%;
                        margin: auto;
                        z-index: -1;
                    }

                    .article-box-image-bottom {
                        position: absolute;
                        bottom: 7.5%;
                        max-width: 85%;
                        max-height: 30%;
                        left: 7.5%;
                        right: 7.5%;
                        margin: auto;
                        z-index: -1;
                    }

                    .article-box-image-bottom-cover {
                        width: 100%;
                        height: auto;
                        z-index: 1;
                        object-fit: contain;
                        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
                        aspect-ratio: 16 / 9;
                        position: relative;
                    }
                    img.article-box-image-bottom-cover {
                        // background-color: white;
                    }

                    .article-box-content {
                        padding: 0.1rem;
                        height: auto;
                        color: ${BRAND_COLOURS.grayDark};
                        -webkit-font-smoothing: antialiased;
                        // background-color: rgba(0, 0, 0, 0.6);
                    }

                    .article-box-wrapper-content {
                        font-size: 0.8rem;
                        padding: 0;
                    }

                    .article-box-top-content {
                        color: ${BRAND_COLOURS.dark};
                        font-size: 0.8rem;
                    }

                    .article-box-middle-content {
                        font-weight: bold;
                        margin: 0.4rem 0;
                    }

                    .cardboard .article-box-middle-content {
                        color: ${BRAND_COLOURS.primary};
                    }

                    .article-box-bottom-content {
                        color: ${BRAND_COLOURS.dark};
                        font-size: 0.8rem;
                    }

                    .sr-only {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        overflow: hidden;
                        clip: rect(0, 0, 0, 0);
                        white-space: nowrap;
                        border: 0;
                    }
                `}
            </style>
            <Col
                xl={columnSizes && columnSizes.xl ? columnSizes.xl : 4}
                lg={columnSizes && columnSizes.lg ? columnSizes.lg : 4}
                md={columnSizes && columnSizes.md ? columnSizes.md : 6}
                sm={columnSizes && columnSizes.sm ? columnSizes.sm : 6}
                xs={columnSizes && columnSizes.xs ? columnSizes.xs : 12}
                className={className}
            >
                <BoxContainer>
                    <div
                        className={`article-box-image-wrapper ${
                            imagePosition === "none" ? "hide" : ""
                        }${wrapperClass ? " " + wrapperClass : ""}`}
                        style={{
                            ...(!image && hideDefaultImage
                                ? {
                                      padding: ".75rem",
                                      backgroundColor: BRAND_COLOURS.gray
                                  }
                                : {}),
                            ...(wrapperContent
                                ? {
                                      padding: "0"
                                  }
                                : {})
                        }}
                    >
                        {wrapperContent && (
                            <div className={"article-box-wrapper-content"}>
                                {wrapperContent}
                            </div>
                        )}

                        <ArticleImage
                            image={image}
                            defaultImage={defaultImage}
                            hideDefaultImage={hideDefaultImage}
                            imageTitle={imageTitle}
                            imageClassName={imageClassName}
                        />
                    </div>
                    <div className={"article-box-content"}>
                        {topContent && (
                            <div className={"article-box-top-content"}>
                                {topContent}
                            </div>
                        )}
                        {middleContent && (
                            <div className={"article-box-middle-content"}>
                                {middleContent}
                            </div>
                        )}
                        {bottomContent && (
                            <div className={"article-box-bottom-content"}>
                                {bottomContent}
                            </div>
                        )}
                    </div>
                </BoxContainer>
            </Col>
        </React.Fragment>
    );
};

ArticleBox.propTypes = {
    image: PropTypes.string,
    imageTitle: PropTypes.string,
    imagePosition: PropTypes.oneOf(["cover", "bottom", "bottom-cover"]),
    imageClassName: PropTypes.string,
    topContent: PropTypes.any,
    middleContent: PropTypes.any,
    bottomContent: PropTypes.any,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkTitle: PropTypes.string,
    className: PropTypes.string,
    marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ArticleBox;
const ArticleImage = ({
    image,
    hideDefaultImage,
    defaultImage,
    imageTitle,
    imageClassName
}: {
    image: any;
    hideDefaultImage?: boolean;
    defaultImage: string;
    imageTitle: any;
    imageClassName: any;
}) => {
    if (!image && hideDefaultImage) return null;
    return (
        <>
            <style jsx>
                {`
                    div::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: url(${URISerializeObject(
                            {
                                fit: "scale",
                                w: "640",
                                q: "50",
                                fm: "jpg",
                                fl: "progressive"
                            },
                            image || defaultImage
                        )});
                        background-size: cover;
                        // background-color: ${BRAND_COLOURS.primary};
                        // core code
                        filter: blur(45px);
                        transform: scale(1.43);
                    }
                    // img {
                    //     background-color: white;
                    // }
                `}
            </style>
            <div className="img-bg">
                {/* <LazyLoad width="300"> */}
                <img
                    // @ts-ignore POSSIBLE ERROR FOUND. Multiple issues
                    src={URISerializeObject(
                        {
                            fit: "scale",
                            w: "640",
                            q: "50",
                            fm: "jpg",
                            fl: "progressive"
                        },
                        image || defaultImage
                    )}
                    alt={imageTitle || "Convergence Blended Finance"}
                    className={imageClassName}
                />
                {/* </LazyLoad> */}
            </div>
        </>
    );
};

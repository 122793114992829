import React from "react";
import theme from "../../theme";

type Props = {
    label?: string;
    type;
    name;
    id;
    className?;
    required?: boolean;
    placeholder?: string;
};
class TextInput extends React.Component<Props> {
    render() {
        const { label, ...inputProps } = this.props;
        return (
            <div>
                {label && <label>{label}</label>}
                <input {...inputProps} />
                <style jsx>{`
                    div {
                        display: inline-block;
                        position: relative;
                    }

                    input {
                        width: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                        border: 1px solid ${theme.colour.gray300};
                    }
                `}</style>
            </div>
        );
    }
}

export default TextInput;

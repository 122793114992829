import React from "react";
import Button from "../../theme/3.0/Form/Button/Button";
import TextInput from "../../theme/3.0/Form/Input/TextInput";
import Col from "../../theme/3.0/Grid/Col";
import Container from "../../theme/3.0/Grid/Container";
import Row from "../../theme/3.0/Grid/Row";
import Link from "../../theme/3.0/Link";

const HomeAbout = () => (
    <Container>
        <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <h3 className={"tw-text-2xl mb-4"}>What is blended finance?</h3>
                <p>
                    Blended finance is the use of catalytic capital from public
                    or philanthropic sources to increase private sector
                    investment in sustainable development. Blended finance has
                    mobilized approximately $200 billion to-date based on
                    Convergence data.
                </p>
                <Link
                    routeKey={"blended-finance"}
                    label={"Learn More"}
                    size={"sm"}
                    isButton
                />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <h3 className={"tw-text-2xl mb-4"}>Stay up-to-date</h3>
                <form
                    method={"post"}
                    action={
                        "//finance.us11.list-manage.com/subscribe/post?u=806cd6e61321e8b1bd6af5409&id=a595f8f9bd"
                    }
                >
                    <label htmlFor={"newsletter_email"}>
                        Join our mailing list to receive the latest updates on
                        Convergence.
                    </label>
                    <span className=" tw-flex">
                        <TextInput
                            type={"email"}
                            name={"EMAIL"}
                            id={"newsletter_email"}
                            className={"form-control mb-2"}
                            placeholder={"Enter your email address"}
                            required={true}
                        />
                        <Button
                            type={"submit"}
                            className={
                                "btn btn-sm btn-primary tw-items-center mb-2"
                            }
                            size={"sm"}
                        >
                            Submit
                        </Button>
                    </span>
                </form>
            </Col>
        </Row>
    </Container>
);

export default HomeAbout;

/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import dynamic from "next/dynamic";
import theme from "../../theme/3.0/theme";
import HomeBanner from "./HomeBanner";
import HomeAbout from "./HomeAbout";
// const HomeAbout = dynamic(() => import("./HomeAbout"), {
//     ssr: true
// });
import HomeLatestNews from "./HomeLatestNews";
// const HomeLatestNews = dynamic(() => import("./HomeLatestNews"), {
//     ssr: true
// });
import ContentfulKnowledgeFeatured from "../knowledge/KnowledgeIndex/ContentfulKnowledgeFeatured";
// const ContentfulKnowledgeFeatured = dynamic(
//     () => import("../knowledge/KnowledgeIndex/ContentfulKnowledgeFeatured"),
//     {
//         ssr: true
//     }
// );
import HomeMembership from "./HomeMembership";
// const HomeMembership = dynamic(() => import("./HomeMembership"), {
//     ssr: true
// });
import HomeDesignFunding from "./HomeDesignFunding";
// const HomeDesignFunding = dynamic(() => import("./HomeDesignFunding"), {
//     ssr: true
// });

type Props = {
    newsArticles;
    grantArticles;
    homeContent;
};
class HomePage extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        );
    }

    render() {
        const { newsArticles, grantArticles, homeContent } = this.props;
        return (
            <React.Fragment>
                <section className={"container-fluid header-banner"}>
                    <HomeBanner homeContent={homeContent} />
                    {/* <HomeHeaderNumbers homeContent={homeContent} /> */}
                </section>
                {/* <section className={"announcement-banner"}>
                    <HomeAnnouncementBanner />
                </section> */}
                {/* <section className={"header-numbers"}></section> */}
                <section
                    className={
                        "featured-resources tw-bg-background-neutral-light"
                    }
                >
                    <ContentfulKnowledgeFeatured />
                </section>
                <section
                    className={
                        "about-newsletter fluid-background tw-bg-background-neutral"
                    }
                >
                    {/* <HomeWIBF /> */}
                    <HomeAbout />
                </section>
                {/* <section className={"about-newsletter fluid-background"}>
                    <HomeAbout />
                </section> */}
                <section className={"latest-news tw-text-white"}>
                    <HomeLatestNews newsArticles={newsArticles} />
                </section>
                <section className={"membership fluid-background tw-bg-white"}>
                    <HomeMembership homeContent={homeContent} />
                </section>
                <section className={"design-funding tw-bg-background-neutral"}>
                    <HomeDesignFunding grantArticles={grantArticles} />
                </section>
                <style jsx global>
                    {`
                        section {
                            padding: 3rem 0;
                        }

                        section.fluid-background {
                            background-color: ${theme.colour.light};
                        }

                        .announcement-banner {
                            padding: 0;
                        }
                        .featured-resources, .latest-news {
                            // color: ${theme.colour.dark};
                            // background-color: ${theme.colour.secondary};
                        }
                        .latest-news {
                            background-color: ${theme.colour.primary};
                        }
                        .header-banner {
                            padding: 0;
                            // background-size: cover;
                            // background-image: url("/reports/sobf2020/images/hero-bg.jpg");
                        }
                        .header-numbers {
                            padding: 2rem 0;
                            background-color: transparent;
                            position: absolute;
                            bottom: 0;
                            color: white;
                        }
                        // .lead {
                        //     display: inline-block;
                        //     // margin: 0 0 1rem 0;
                        //     padding: 10px;
                        //     font-size: 2.5rem;
                        //     font-weight: normal;
                        //     // background-color: rgba(0, 0, 0, 0.3);
                        //     -webkit-font-smoothing: antialiased;
                        //     color: ${theme.colour.white};
                        //     line-height: 120%;
                        // }
                        #content {
                            margin-top: -90px
                        }
                        .latest-news .article-box-content,
                        .latest-news .article-box-bottom-content,
                        .latest-news .article-box-top-content {
                            color: white
                        }
                    `}
                </style>
            </React.Fragment>
        );
    }
}

export default HomePage;

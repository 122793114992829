import React from "react";
import { DateService } from "../../../service/DateService";
import ArticleBox from "../../layout/ArticleBox/ArticleBox";
import Container from "../../theme/3.0/Grid/Container";
import Row from "../../theme/3.0/Grid/Row";
import Link from "../../theme/3.0/Link";

const HomeLatestNews = ({ newsArticles }) => (
    <div>
        <Container>
            <h3 aria-label={"Latest News"} className="section-header">
                Latest News
            </h3>
            <Row>
                {newsArticles &&
                    newsArticles.map((article) => {
                        const link = {
                            routeKey: "news-and-events-view-news",
                            query: { id: article.id }
                        };
                        return (
                            <ArticleBox
                                key={article.id}
                                topContent={article.type}
                                middleContent={article.title}
                                bottomContent={DateService.format(
                                    article.date,
                                    DateService.FORMAT_DD_MMM_YY
                                )}
                                link={link}
                                linkTitle={article.title}
                                image={article.image}
                                imageTitle={article.title}
                                imagePosition={"bottom-cover"}
                                marginBottom={"30px"}
                            />
                        );
                    })}
            </Row>
            <Link
                routeKey={"news-and-events"}
                label={"View More"}
                isButton
                size={"sm"}
                colour="secondary"
            />
        </Container>
        <style jsx>{`
            h3 {
                // margin-bottom: 1rem;
                // font-size: 1.4rem;
                // font-weight: bold;
                -webkit-font-smoothing: antialiased;
            }
        `}</style>
    </div>
);

export default HomeLatestNews;

/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import { WithRouterProps } from "next/dist/client/with-router";
import { withRouter } from "next/router";
import React, { PropsWithChildren } from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { LOGGED_IN_USER_QUERY } from "../../../../graphql/query/UserQuery";
import { logPageView } from "../../../../utils/Analytics";

interface Props extends WithRouterProps {}
class PageWrapper extends React.Component<
    WithApolloClient<PropsWithChildren<Props>>
> {
    constructor(props) {
        super(props);
        this.state = {
            asPath: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.router.asPath !== state.asPath) {
            if (process.browser) {
                props.client
                    .query({
                        query: LOGGED_IN_USER_QUERY,
                        fetchPolicy: "cache-only"
                    })
                    .then(({ data }) => {
                        const user = data && data.user ? data.user : null;
                        const userId = user ? user.id : null;
                        const institutionId =
                            user && user.institution_id
                                ? user.institution_id
                                : null;

                        logPageView(userId, institutionId);
                    });
            }

            return {
                asPath: props.router.asPath
            };
        }

        return null;
    }

    render() {
        return this.props.children;
    }
}

const PageWithHOCs = withRouter<Props>(withApollo<Props>(PageWrapper));

export default PageWithHOCs;

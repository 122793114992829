/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { PAGE_KNOWLEDGE } from "../../../../constants";
import { ContentfulService } from "../../../../service/ContentfulService";
import KnowledgeArticleBox from "../KnowledgeArticleBoxLegacy";
import Container from "../../../theme/3.0/Grid/Container";

type Props = {};

type State = {
    loading: boolean;
    featured;
};
class ContentfulKnowledgeFeatured extends React.Component<Props, State> {
    contentfulService: ContentfulService;
    constructor(props) {
        super(props);
        this.contentfulService = new ContentfulService();
        this.state = {
            loading: false,
            featured: []
        };
    }

    componentDidMount() {
        this.fetchFeaturedArticles();
    }

    fetchFeaturedArticles() {
        this.contentfulService
            .getArticles(PAGE_KNOWLEDGE, 2, 0, {
                "fields.featured": "Yes",
                "fields.authorization[in]": "Public,Members",
                // "fields.authorization[nin]": "Tri Hita Karana",
                order: "-fields.PublicationDate"
            })
            .then(({ articles }) => this.setState({ featured: articles }));
    }

    render() {
        const { featured } = this.state;
        return (
            <div className={"pt-4"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div
                                className={
                                    "text-primary-foreground section-header mb-3"
                                }
                            >
                                Featured Resources
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        {featured &&
                            featured.length > 0 &&
                            featured.map((article) => (
                                <KnowledgeArticleBox
                                    key={article.id}
                                    article={article}
                                />
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentfulKnowledgeFeatured;

/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";

// Pages
import HomePage from "../components/page/index/HomePage";
import Page from "../components/theme/3.0/Page/Page";
import Authenticated from "../components/Authenticated";
import ContentfulService from "../service/ContentfulService";
import { withApollo } from "../utils/WithApollo";
import { authenticateUser } from "../utils/AuthenticationUtility";
import { getCookiesFromCtx } from "../utils/CommonUtils";

const Index = ({
    newsArticles,
    grantArticles,
    homeContent,
    currentRouteKey
}) => (
    <Authenticated passNullUser enableUserChecks>
        {(user, authorizationService) => (
            <Page
                pageTitle={
                    "Convergence - The Global Network for Blended Finance"
                }
                includePageTitleTag={false}
                description={
                    "Convergence is the global network for blended finance. We generate blended finance data, intelligence, and deal flow to increase private sector investment in developing countries for the United Nations (UN) Sustainable Development Goals (SDGs)."
                }
                user={user}
                authorizationService={authorizationService}
                currentRouteKey={currentRouteKey}
            >
                <HomePage
                    newsArticles={newsArticles}
                    grantArticles={grantArticles}
                    homeContent={homeContent}
                />
            </Page>
        )}
    </Authenticated>
);

Index.getInitialProps = async (ctx) => {
    await authenticateUser(ctx.apolloClient, ctx, undefined);

    const cookiesJSON = getCookiesFromCtx(ctx);
    const contentfulService = ContentfulService(cookiesJSON);
    const newsArticles = await contentfulService.getNewsArticles(3);
    const grantArticles = await contentfulService.getGrantArticles(6);
    let page = [
        "Home: Funding Sought",
        "Home: Deals Fundraising",
        "Home: Member Users",
        "Home: Member Institutions",
        "Home: Grants Awarded",
        "Home: Exclusive Publications",
        "Home: Expected Impact",
        "Home: Capital Catalyzed",
        "Home: Select Members"
    ].join(",");
    const homeContent = await contentfulService.getPageInfo(page);

    return {
        newsArticles,
        grantArticles,
        homeContent,
        currentRouteKey: ctx.query.routeKey
    };
};

export default withApollo(Index);

import React from "react";
import ReactCountUp from "react-countup";
import Container from "../../theme/3.0/Grid/Container";
import Row from "../../theme/3.0/Grid/Row";
import Col from "../../theme/3.0/Grid/Col";
import theme from "../../theme/3.0/theme";

const HomeHeaderNumbers = ({ homeContent }) => (
    <div className="tw-flex tw-flex-col tw-h-full text-white tw-w-full">
        {/* <Container> */}
        {/* <h2 className="tw-p-4 md:tw-p-10 tw-uppercase tw-text-bold text-center tw-text-xl sm:tw-text-2xl lg:tw-text-4xl">
            Convergence Is The Global Network For <br />
            Blended Finance
        </h2>
        <h4
            className={
                "tw-text-lg sm:tw-text-2xl lg:tw-text-3xl tw-p-2 sm:tw-px-4 md:tw-px-10 text-center"
            }
        >
            Blended Finance Data, Intelligence, & <br />
            Deal Flow To Increase Private Sector
            <br />
            Investment In Developing Countries
        </h4>
 */}
        {homeContent && homeContent.stats && (
            <div className="tw-py-6 md:tw-py-10 text-center container">
                <Row>
                    <Col
                        xl={3}
                        lg={3}
                        md={6}
                        sm={6}
                        xs={6}
                        className={"number-widget"}
                    >
                        <div className={"stat text-center"}>
                            <div className={"title"}>
                                Capital
                                <br />
                                Mobilized
                            </div>
                            <div className={"number"}>
                                <ReactCountUp
                                    duration={2}
                                    start={0}
                                    end={
                                        homeContent.stats.capital_catalyzed &&
                                        parseInt(
                                            homeContent.stats.capital_catalyzed,
                                            10
                                        )
                                    }
                                    separator={","}
                                    formattingFn={(val) => {
                                        return `$${new Intl.NumberFormat(
                                            "en-US",
                                            {
                                                notation: "compact",
                                                compactDisplay: "short"
                                            }
                                        ).format(val)}+`;
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col
                        xl={3}
                        lg={3}
                        md={6}
                        sm={6}
                        xs={6}
                        className={"number-widget"}
                    >
                        <div className={"stat"}>
                            <div className={"title"}>
                                Member
                                <br />
                                Institutions
                            </div>
                            <div className={"number"}>
                                <ReactCountUp
                                    duration={2}
                                    start={0}
                                    end={
                                        homeContent.stats.member_institutions &&
                                        parseInt(
                                            homeContent.stats
                                                .member_institutions,
                                            10
                                        )
                                    }
                                    separator={","}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col
                        xl={3}
                        lg={3}
                        md={6}
                        sm={6}
                        xs={6}
                        className={"number-widget"}
                    >
                        <div className={"stat"}>
                            <div className={"title"}>
                                Deals Currently
                                <br />
                                Fundraising
                            </div>
                            <div className={"number"}>
                                <ReactCountUp
                                    duration={2}
                                    start={0}
                                    end={
                                        homeContent.stats.deals_fundraising &&
                                        parseInt(
                                            homeContent.stats.deals_fundraising,
                                            10
                                        )
                                    }
                                    separator={","}
                                />{" "}
                                <div className={"title"}>
                                    Seeking (
                                    <ReactCountUp
                                        duration={2}
                                        start={0}
                                        end={
                                            homeContent.stats.funding_sought &&
                                            // TS CODE CHANGE parseFloat takes 1 arg
                                            parseFloat(
                                                homeContent.stats.funding_sought
                                            )
                                        }
                                        separator={","}
                                        decimals={2}
                                        formattingFn={(val) => {
                                            return `$${new Intl.NumberFormat(
                                                "en-US",
                                                {
                                                    notation: "compact",
                                                    compactDisplay: "short"
                                                }
                                            ).format(val)}`;
                                        }}
                                    />
                                    )
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xl={3}
                        lg={3}
                        md={6}
                        sm={6}
                        xs={6}
                        className={"number-widget"}
                    >
                        <div className={"stat"}>
                            <div className={"title"}>
                                Intelligence
                                <br />
                                Products
                            </div>
                            <div className={"number"}>
                                <ReactCountUp
                                    duration={2}
                                    start={0}
                                    end={
                                        homeContent.stats.deals_fundraising &&
                                        parseInt(
                                            homeContent.stats
                                                .exclusive_publications,
                                            10
                                        )
                                    }
                                    separator={","}
                                />{" "}
                            </div>
                        </div>
                    </Col>
                    {/* <Col
                        xl={3}
                        lg={3}
                        md={6}
                        sm={6}
                        xs={6}
                        className={"number-widget"}
                    >
                        <div className={"stat"}>
                            <div className={"title"}>
                                Grants
                                <br />
                                Awarded
                            </div>
                            <div className={"number"}>
                                <ReactCountUp
                                    duration={2}
                                    start={0}
                                    end={
                                        homeContent.stats.deals_fundraising &&
                                        parseInt(
                                            homeContent.stats.grants_awarded,
                                            10
                                        )
                                    }
                                    separator={","}
                                />{" "}
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col
                        xl={3}
                        lg={3}
                        md={6}
                        sm={4}
                        xs={6}
                        className={"number-widget"}
                    >
                        <div className={"stat"}>
                            <div className={"title"}>
                                Total Funding
                                <br />
                                Sought
                            </div>
                            <div className={"number"}>
                                <ReactCountUp
                                    duration={2}
                                    start={0}
                                    end={
                                        homeContent.stats.funding_sought &&
                                        // TS CODE CHANGE parseFloat takes 1 arg
                                        parseFloat(
                                            homeContent.stats.funding_sought
                                        )
                                    }
                                    separator={","}
                                    decimals={2}
                                    formattingFn={(val) => {
                                        return `$${new Intl.NumberFormat(
                                            "en-US",
                                            {
                                                notation: "compact",
                                                compactDisplay: "short"
                                            }
                                        ).format(val)}`;
                                    }}
                                />
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </div>
        )}
        {/* </Container> */}

        <style jsx>
            {`
                 {
                    // text-align: left;
                    font-size: 1.1rem;
                }

                h2 {
                    -webkit-font-smoothing: antialiased;
                    // margin-top: 2rem;
                    // margin-bottom: 0rem;
                    // font-size: 2.25rem;
                    font-weight: 600;
                    z-index: 2;
                }

                .stat {
                    text-align: center;
                }

                .title {
                    // font-size: 1.2em;
                    color: ${theme.colour.white};
                    line-height: 1;
                }

                .number {
                    font-size: 2em;
                }

                div :global(.number-widget) {
                    margin-bottom: 0.5rem;
                    border-right: none;
                }

                div :global(.number-widget:last-of-type) {
                    border-right: 0;
                }

                @media (min-width: ${theme.breakpoint.md}) {
                    div :global(.number-widget) {
                        margin-bottom: 0;
                        border-right: 1px solid ${theme.colour.gray200};
                    }
                }
                .text-shadow {
                    text-shadow: 1px 1px 5px rgba(51, 51, 51, 1);
                }
            `}
        </style>
    </div>
);

export default HomeHeaderNumbers;

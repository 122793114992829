import React from "react";
import theme from "../../theme/3.0/theme";
import HomeHeaderNumbers from "./HomeHeaderNumbers";

const HomeBanner = ({ homeContent }) => (
    <div style={{ paddingTop: 0 }}>
        <div className="hero-container tw-flex-col">
            <div className="bg-video-wrapper">
                <video
                    autoPlay
                    playsInline
                    muted
                    loop
                    preload="metadata"
                    className="hero-bg tw-filter tw-grayscale tw-brightness-75 tw-saturate-125 tw-contrast-200"
                    src="https://videos.ctfassets.net/4cgqlwde6qy0/5fZg64prh5LJSCDdV1USSK/e208415c90cc82fde797a70fdac29eb1/hero_banner.mp4"
                    poster="https://images.ctfassets.net/4cgqlwde6qy0/3RyIGWQI54S3FOJPxBH3Lm/cb888c4840a03a7e17dacd258cb282cb/hero-banner-frame.jpg?fit=scale&q=50&fm=jpg&w=1920&fl=progressive"
                />
            </div>
            <div className="container wrapper">
                <div className="hero tw-my-24 tw-rounded gradient-mask-from-left">
                    <h2 className="tw-p-4 md:tw-p-10 tw-uppercase tw-font-bold text-center tw-text-lg sm:tw-text-xl lg:tw-text-2xl">
                        Convergence Is The Global Network For <br />
                        Blended Finance
                    </h2>
                    <h4
                        className={
                            "tw-text-md sm:tw-text-md lg:tw-text-xl tw-p-2 sm:tw-px-4 md:tw-px-10 text-center"
                        }
                    >
                        Blended Finance Data, Intelligence, & Deal Flow To
                        Increase Private Sector Investment In Developing
                        Countries
                    </h4>
                    <a href="/" className="tw-p-6">
                        <img
                            src="/static/logo_emphasized_tag-white.svg"
                            alt="Convergence"
                            style={{ width: "100%" }}
                        />
                    </a>

                    <HomeHeaderNumbers homeContent={homeContent} />
                </div>
            </div>
        </div>

        <style jsx>{`
            div {
                text-align: center;
            }

            .lead-img {
                display: block;
                margin: 0 auto 2rem auto;
            }
            .wrapper {
                // max-width: 1100px;
                display: flex;
                align-items: center;
                justify-content: start;
                flex-direction: column;
                // margin: 4rem auto 0;
                // padding: 125px 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            .wrapper.wrapper-t {
                padding-top: 50px;
            }

            .wrapper.wrapper-b {
                padding-bottom: 50px;
            }

            @media (min-width: ${theme.breakpoint.md}) {
                .wrapper > div {
                    width: 83%;
                    height: 100%;
                }
            }

            .wrapper > div {
                height: 100%;
            }

            .hero {
                display: flex;
                align-items: center;
                justify-content: end;
                flex-direction: column;
                color: #ffffff;
                z-index: 2;
                // background-color: #26a4d985;
                // padding: 0rem 6rem;
                // background-color: ${theme.colour.secondary}cc;
                background-color: ${theme.colour.dark}75;
            }

            .hero h1 {
                text-align: center;
            }
            // .hero * {
            //     text-shadow: 1px 5px 8px rgba(51, 51, 51, 1);
            // }

            .hero-container {
                // background-image: url("/reports/sobf2020/images/hero-bg.jpg");
                // background-size: cover;
                min-height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }
            .hero-container::before {
                content: "";
                position: fixed;
                left: 0;
                right: 0;
                z-index: -1;
              
                display: block;
                // background-image: url("/reports/sobf2020/images/hero-bg.jpg");
                background-size:cover;
                width: 100%;
                height: 100%;
                filter: grayscale(100%) brightness(175%);
              }
            .bg-video-wrapper {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .bg-video-wrapper video {
                min-width: 100%;
                max-width: none;
                min-height: 100%;
                width: auto;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .hero:before {
                position: absolute;
                content: "";
                // background-color: #00000075;
                opacity: 0.4;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            }
            .lead {
                display: inline-block;
                margin: 0 0 4rem 0;
                padding: 10px;
                font-size: 2rem;
                font-weight: normal;
                // background-color: rgba(0, 0, 0, 0.3);
                -webkit-font-smoothing: antialiased;
                color: ${theme.colour.white};
                line-height: 120%;
            }
        `}</style>
    </div>
);

export default HomeBanner;

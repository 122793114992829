import React from "react";
import Link from "../../theme/3.0/Link";
import Container from "../../theme/3.0/Grid/Container";
import Row from "../../theme/3.0/Grid/Row";
import ArticleBox from "../../layout/ArticleBox/ArticleBox";
import { getWindowBadgeColour } from "../../../utils/CommonUtils";

const HomeDesignFunding = ({ grantArticles }) => (
    <div>
        <Container>
            <h3 aria-label={"Design Funding"} className="section-header mb-3">
                Design Funding
            </h3>
            <p>
                Convergence offers design funding grants to blended finance
                practitioners looking to secure feasibility, proof of concept,
                or expansion stage funding alongside support to develop and
                launch early-stage financial structures that aim to attract
                private capital.
            </p>

            <Link
                routeKey={"design-funding"}
                title={"Learn more about Design Funding"}
                label={"Learn More"}
                size={"sm"}
                isButton
            />

            <h4
                className={"latest-grants-title mt-4 mb-3"}
                aria-label={"Latest Grants"}
            >
                LATEST GRANTS
            </h4>
            <Row>
                {grantArticles &&
                    grantArticles.map((article) => {
                        const link = {
                            routeKey:
                                "design-funding-grant-portfolio-view-grant",
                            href: "/design-funding/:id/view",
                            as: `/design-funding/${article.id}/view`,
                            query: { id: article.id }
                        };

                        return (
                            <ArticleBox
                                key={article.id}
                                wrapperContent={
                                    <div
                                        className={getWindowBadgeColour(
                                            article.designFundingWindow.fields
                                                .windowName
                                        )}
                                        style={{
                                            position: "relative"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                fontSize: ".65rem",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {article.designFundingWindow &&
                                                article.designFundingWindow
                                                    .fields &&
                                                article.designFundingWindow
                                                    .fields.windowName && (
                                                    <div
                                                        className={`   border-white`}
                                                        style={{
                                                            paddingTop: ".3rem",
                                                            paddingBottom:
                                                                ".3rem",
                                                            paddingLeft:
                                                                ".36rem",
                                                            paddingRight:
                                                                ".36rem",
                                                            textAlign: "end"
                                                        }}
                                                    >
                                                        {article.designFundingWindow.fields.windowName.replace(
                                                            "Design Funding Window",
                                                            "Window"
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                }
                                topContent={
                                    <>
                                        <div
                                            style={{
                                                position: "relative"
                                            }}
                                        >
                                            {article.organization}
                                        </div>
                                    </>
                                }
                                middleContent={article.title}
                                link={link}
                                linkTitle={article.title}
                                image={article.image}
                                hideDefaultImage
                                imageTitle={article.title}
                                imagePosition={"logo"}
                                className={"cardboard"}
                            />
                        );
                    })}
            </Row>
            <Link
                routeKey={"design-funding-grant-portfolio"}
                title={"View more grants"}
                label={"View More"}
                size={"sm"}
                isButton
                colour="secondary"
            />
        </Container>
        <style jsx>{`
            h3 {
                // margin-bottom: 1rem;
                // font-size: 1.4rem;
                // font-weight: bold;
                -webkit-font-smoothing: antialiased;
            }
            h4 {
                font-weight: normal;
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }
        `}</style>
    </div>
);

export default HomeDesignFunding;

import React from "react";
import LazyLoad from "react-lazy-load";
import ReactMarkdown from "react-markdown";
import Container from "../../theme/3.0/Grid/Container";
import Row from "../../theme/3.0/Grid/Row";
import Col from "../../theme/3.0/Grid/Col";
import Link from "../../theme/3.0/Link";

function LinkRenderer({ ...props }) {
    return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children}
        </a>
    );
}

function RootRenderer({ ...props }) {
    return <div {...props} />;
}
function ParagraphRenderer({ ...props }) {
    return <div {...props} />;
}
function ImageRenderer({ ...props }) {
    return (
        <LazyLoad className={"select-member"}>
            <img {...props} />
        </LazyLoad>
    );
}

const HomeMembership = ({ homeContent }) => (
    <Container>
        <h3 aria-label={"Membership"} className="section-header mb-3">
            Membership
        </h3>
        <p>
            Convergence members are part of a global community of institutions
            and businesses dedicated to driving capital to where it is needed
            most. Member institutions include private investors looking to
            diversify their portfolios, businesses seeking capital, as well as
            public agencies and philanthropic foundations looking to make their
            funds go further. Any institution can register as a member, however
            as a regulated entity, certain products and services are only
            available to accredited / sophisticated investors. What Convergence
            offers to members:
        </p>
        <div className={"plans mt-4"}>
            <Row>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        src="/static/data-&amp;-intelligence.svg"
                        className="icon mb-3"
                    />
                    <h4 className={"mb-2"}>Data &amp; Intelligence</h4>
                    <ul>
                        <li>
                            Access to data on past blended finance transactions,
                            incl. impact targets, blending approach, and
                            investors.
                        </li>
                        <li>
                            Quarterly briefings providing critical analysis and
                            insights into blended finance trends.
                        </li>
                        <li>
                            Analytical tools to evaluate deal and investor
                            trends; bespoke analysis on request.
                        </li>
                        <li>
                            Case studies on blended finance transactions,
                            thought pieces on important themes.
                        </li>
                        <li>Curated knowledge library of key resources.</li>
                        <li>
                            Knowledge and data recommendations based on user
                            interests and preferences.
                        </li>
                        <li>Quarterly webinars on select topics.</li>
                    </ul>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        src="/static/deal-flow-&-networking.svg"
                        className="icon mb-3"
                    />
                    <h4 className={"mb-2"}>Deal Flow &amp; Networking</h4>
                    <ul>
                        <li>
                            For investors: Access information on, and connect
                            with, high quality deals currently fundraising; deal
                            recommendations based on interests.
                        </li>
                        <li>
                            For deal sponsors: Post your deal, get matched with
                            investors, connect and communicate.
                        </li>
                        <li>
                            View profiles of Convergence members, their
                            interests and focus areas.
                        </li>
                        <li>
                            Secure messaging with investors and deal sponsors.
                        </li>
                        <li>
                            Quarterly Convergence member meetings and events.
                        </li>
                        <li>Tracker of events relevant to blended finance.</li>
                    </ul>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        src="/static/learning-&amp;-capacity.svg"
                        className="icon mb-3"
                    />
                    <h4 className={"mb-2"}>Learning &amp; Capacity</h4>
                    <ul>
                        <li>
                            In-depth on-boarding to ascertain institution
                            interests and needs for blended finance.
                        </li>
                        <li>
                            Bespoke blended finance learning sessions and
                            workshops with institution staff members and
                            industry experts.
                        </li>
                        <li>
                            Access to online educational modules on blended
                            finance.
                        </li>
                        <li>
                            Early access or preferential rates for blended
                            finance executive education programs at partner
                            universities.
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
        <Link
            routeKey={"sign-up"}
            title={"Register for a Convergence Account"}
            label={"Register"}
            size={"sm"}
            isButton
        />{" "}
        <Link
            routeKey={"membership"}
            title={"Learn more about membership"}
            label={"Learn More"}
            size={"sm"}
            isButton
        />
        <p className={"mt-5 mb-0"}>
            <strong>Select members</strong> include the leading public
            investors, foundations, private investors, and deal sponsors in
            blended finance:
        </p>
        {homeContent.selectMembersContent && (
            // TS CODE CHANGE
            // https://github.com/remarkjs/react-markdown/blob/main/changelog.md#change-renderers-to-components
            // see conversin table. Old: root: New: wrap ReactMarkdown in div
            <div>
                <ReactMarkdown
                    className={"select-members"}
                    // TS CODE CHANGE
                    // https://github.com/remarkjs/react-markdown/blob/main/changelog.md#change-renderers-to-components
                    components={{
                        // TS CODE CHANGE a, p and img:
                        //   see conversion table in https://github.com/remarkjs/react-markdown/blob/main/changelog.md#change-renderers-to-components
                        a: LinkRenderer,
                        // root: RootRenderer, // see note one level up from ReactMarkdown
                        p: ParagraphRenderer,
                        img: ImageRenderer
                    }}
                >
                    {/* TS CODE CHANGE
                    https://github.com/remarkjs/react-markdown/blob/main/changelog.md#change-source-to-children */}
                    {homeContent.selectMembersContent}
                </ReactMarkdown>
            </div>
        )}
        <Link
            routeKey={"member"}
            size={"sm"}
            label={"Explore our members"}
            className={"btn-md btn-primary mb-3"}
            isButton
        />
        <style jsx>{`
            h3 {
                // margin-bottom: 1rem;
                // font-size: 1.4rem;
                // font-weight: bold;
                -webkit-font-smoothing: antialiased;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            ul li {
                margin-bottom: 1rem;
            }

            @media (min-width: 768px) {
                .row div[class*="col-"] + div[class*="col-"] {
                    border-left: 1px solid rgb(230, 230, 230);
                }

                .row div[class*="col-"]:first-child {
                    border-left: none;
                }
            }

            .icon {
                max-height: 75px;
                max-width: 100px;
            }

            :global(.select-members) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                text-align: center;
                align-items: center;
                justify-content: center;
            }

            :global(.select-members a) {
                display: inline-block;
            }
        `}</style>
    </Container>
);

export default HomeMembership;

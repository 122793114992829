import React from "react";
import theme from "../../theme";

type Props = {
    children;
    size?;
    colour?;
    className?;
    noBackground?;
    onClick?;
    type?;
};

const Button = ({
    children,
    size = "md",
    colour = "primary",
    className,
    noBackground,
    ...props
}: Props) => {
    const definedClasses =
        `btn btn-${size} btn-${colour}` + (className ? ` ${className}` : "");
    return (
        <React.Fragment>
            <button {...props} className={definedClasses}>
                {children}
                <style jsx>
                    {`
                        .btn {
                            display: inline-block;
                            font-weight: 400;
                            color: #212529;
                            text-align: center;
                            vertical-align: middle;
                            user-select: none;
                            background-color: transparent;
                            border: 1px solid transparent;
                            padding: 0.375rem 0.75rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            border-radius: 2px;
                            transition: color 0.15s ease-in-out,
                                background-color 0.15s ease-in-out,
                                border-color 0.15s ease-in-out,
                                box-shadow 0.15s ease-in-out;
                        }

                        .btn:hover,
                        .btn.hover {
                            color: ${theme.colour.gray900};
                            text-decoration: none;
                            cursor: pointer;
                        }

                        .btn:focus,
                        .btn.focus {
                            outline: 0;
                            box-shadow: 0 0 0 0.2rem ${theme.colour.primary};
                        }

                        .btn.btn-sm {
                            padding: ${theme.button.sm.padding};
                            font-size: ${theme.button.sm.fontSize};
                        }

                        .btn.btn-md {
                            padding: ${theme.button.md.padding};
                            font-size: ${theme.button.md.fontSize};
                        }

                        .btn.btn-lg {
                            padding: ${theme.button.lg.padding};
                            font-size: ${theme.button.lg.fontSize};
                        }

                        .btn.btn-primary {
                            color: ${noBackground
                                ? theme.colour.primary
                                : theme.colour.white};
                            background-color: ${noBackground
                                ? "transparent"
                                : theme.colour.primary};
                            border-color: ${noBackground
                                ? "transparent"
                                : theme.colour.primary};
                        }

                        .btn.btn-primary:hover {
                            color: ${noBackground
                                ? "#2b6699"
                                : theme.colour.white};
                            background-color: ${noBackground
                                ? "transparent"
                                : "#2b6699"};
                            border-color: ${noBackground
                                ? "transparent"
                                : "#285f8f"};
                        }
                    `}
                </style>
            </button>
        </React.Fragment>
    );
};

export default Button;
